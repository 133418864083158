import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

/**
 * @params Open
 * @returns Modal for Authentication error
 */
export const AuthAlertDialog = (props) => {
  const { open } = props;
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Authentication Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session has expired or the provided token is invalid. Please log in again to
            continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>Login again</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Default props for component
AuthAlertDialog.defaultProps = {
  open: true
};

export default React.memo(AuthAlertDialog);
