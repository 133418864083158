import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import oasisLogo from "../../logo.svg";
import RfqLogoWhite from "../../RfqLogoWhite.svg";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import variables from "Styles/variables.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { useUserContext } from "Hooks/UserContext";
import { Paths } from "Router/Paths";
import { Divider } from "@mui/material";

const pages = [
  {
    name: "Users",
    path: Paths.users
  },
  {
    name: "Instruments",
    path: Paths.instruments
  }
  // {
  //   name: "Logs",
  //   path: "#"
  // }
];

function AdminHeader() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  /* eslint-disable */
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //get User Details
  const { userDetails, setLoggedIn } = useUserContext();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setAnchorElUser(null);
  };

  const logout = () => {
    if (localStorage.getItem("Authorization")) {
      localStorage.removeItem("Authorization");
      setLoggedIn(false);
      setAnchorElUser(null);
      navigate(Paths.admin);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        // boxShadow: "none",
        backgroundImage: "none",
        borderBottom: "1px solid rgb(228 228 228 / 20%)",
        boxShadow: "none"
      }}>
      <Container maxWidth="fixed">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              width: "130px"
            }}>
            <img style={{ width: "100%" }} src={RfqLogoWhite} alt="Oasis pro markets" />
          </Typography>
          <Typography variant="h6" sx={{ display: { xs: "none", md: "flex" } }}>
            MarketPlace
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: 3,
              mr: 2,
              height: "30px",
              alignSelf: "center",
              display: { xs: "none", md: "flex" }
            }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Settings"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}>
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  selected={page.path === pathname}
                  onClick={() => handleNavigation(page.path)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none"
            }}>
            <img src={RfqLogoWhite} alt="Oasis pro markets" style={{ width: "50%" }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleNavigation(page.path)}
                sx={{
                  my: 2,
                  color: page.path === pathname ? variables.secondary : variables.colorWhite,
                  display: "block"
                }}>
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex" }}>
            {/* <Button variant="contained" color="secondary">
              Connect wallet
            </Button> */}
            {/* Notification */}
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
              className="icon-button">
              <Badge badgeContent={0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* User profile */}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} className="icon-button" sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <AccountCircle />
              </IconButton>
            </Tooltip>

            {/* User profile menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem>
                <Typography sx={{ pl: 1, pr: 1, display: "flex" }}>
                  <AccountCircleRoundedIcon fontSize="medium" sx={{ mr: 0.6 }} />
                  {userDetails && userDetails?.email}
                </Typography>
              </MenuItem>
              <MenuItem onClick={logout}>
                <Typography sx={{ pl: 1, pr: 1, display: "flex" }}>
                  <LogoutIcon fontSize="medium" sx={{ mr: 0.6 }} />
                  logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AdminHeader;
