import { useState, useMemo } from "react";
import "./App.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { getDesignTokens } from "Helpers/ats.helper";
import { ColorModeContext } from "Helpers/contexts";
import { UserContextProvider } from "Hooks/UserContext";
import AppLayout from "Components/Shared/AppLayout";
import { InstrumentContextProvider } from "Hooks/InstrumentContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ServicesProvider } from "Hooks/ServicesContext";
import { SnackbarProvider } from "notistack";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  /* Theme mod configuration */
  const [mode, setMode] = useState("dark");
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      }
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <ServicesProvider>
                <UserContextProvider>
                  <InstrumentContextProvider>
                    <CssBaseline />
                    <div className={"appContainer " + mode}>
                      <AppLayout />
                    </div>
                  </InstrumentContextProvider>
                </UserContextProvider>
              </ServicesProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
