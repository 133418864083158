import { lazy } from "react";
import { Paths } from "./Paths";

export const INVESTOR = "INVESTOR";
export const DEALER = "DEALER";
export const ADMIN = "ADMIN";
export const ROLES = { INVESTOR, DEALER, ADMIN };

export const privateRoutes = {
  INVESTOR: [
    {
      path: Paths.base,
      ComponentIn: lazy(() => import("Components/Dashboard/index"))
    },
    {
      path: Paths.investorHistory,
      ComponentIn: lazy(() => import("Components/InvestorHistory/InvestorHistory"))
    }
  ],
  DEALER: [
    {
      path: Paths.base,
      ComponentIn: lazy(() => import("Components/DealerPanel/DealerPanel"))
    },
    {
      path: Paths.dealerPanel,
      ComponentIn: lazy(() => import("Components/DealerPanel/DealerPanel"))
    }
  ],
  ADMIN: [
    {
      path: Paths.users,
      ComponentIn: lazy(() => import("Components/AdminPanel/UserList"))
    },
    {
      path: Paths.instruments,
      ComponentIn: lazy(() => import("Components/AdminPanel/InstrumentList"))
    }
  ]
};

export const publicRoutes = [
  {
    path: Paths.nf,
    ComponentIn: lazy(() => import("Components/404/index"))
  },
  {
    path: Paths.admin,
    ComponentIn: lazy(() => import("Components/Authentication/login"))
  }
];
