import variables from "Styles/variables.scss";
import jwt_decode from "jwt-decode";
import { ROLES } from "Router/Routes";
import dayjs from "dayjs";
import moment from 'moment-timezone';

export const getDesignTokens = (mode) => ({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          // Some CSS
          //   backgroundImage: "none",
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {},
        colorError: {
          backgroundColor: variables.colorRedLight
        },
        colorWarning: {
          backgroundColor: variables.lightGreyOver
        }
      }
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: "0.75rem",
      fontWeight: variables.fontWeight500,
      fontFamily: variables.fontFamily
    },
    h3: {
      fontWeight: variables.fontWeight500,
      fontSize: variables.headingFontSize
    },
    fontFamily: variables.fontFamily,
    fontSize: 8.75
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
        // palette values for light mode
        // // primary: amber,
        divider: variables.colorGrey,
        // text: {
        //   primary: grey[900],
        //   secondary: grey[800],
        // },
        background: {
          default: variables.lightBackground,
          paper: variables.lightBackground
        },
        success: {
          main: variables.colorGreen
        },
        error: {
          main: variables.colorRed
        },
        secondary: {
          main: variables.colorBlue
        }
      }
      : {
        background: {
          default: variables.darkBackground,
          paper: variables.darkBackground
        },
        success: {
          main: variables.colorGreen
        },
        error: {
          main: variables.colorRed
        },
        secondary: {
          main: variables.colorBlue
        },
        // palette values for dark mode
        // primary: deepOrange,
        divider: variables.tabBorderColor,
        // background: {
        //   default: deepOrange[900],
        //   paper: deepOrange[900],
        // },
        // text: {
        //   primary: '#fff',
        //   secondary: grey[500],
        // },
        text: {
          grey: variables.tableHeadColor
        }
      })
  }
});

/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1); // 1000
 *   roundToNearest(1000.5, 0.5);  // 1000.5
 *
 * @param {number} value    The number to round
 * @param {number} interval The numeric interval to round to
 * @return {number}
 */
export const roundToNearest = (value, interval) => {
  return Math.floor(value / interval) * interval;
};

/**
 * Groups price levels by their price
 * Example:
 *
 *  groupByPrice([ [1000, 100], [1000, 200], [993, 20] ]) // [ [ 1000, 300 ], [ 993, 20 ] ]
 *
 * @param levels
 */
export const groupByPrice = (levels) => {
  return levels
    .map((level, idx) => {
      const nextLevel = levels[idx + 1];
      const prevLevel = levels[idx - 1];

      if (nextLevel && level[0] === nextLevel[0]) {
        return [level[0], level[1] + nextLevel[1]];
      } else if (prevLevel && level[0] === prevLevel[0]) {
        return [];
      } else {
        return level;
      }
    })
    .filter((level) => level.length > 0);
};

/**
 * Group price levels by given ticket size. Uses groupByPrice() and roundToNearest()
 * Example:
 *
 * groupByTicketSize([ [1000.5, 100], [1000, 200], [993, 20] ], 1) // [[1000, 300], [993, 20]]
 *
 * @param levels
 * @param ticketSize
 */
export const groupByTicketSize = (levels, ticketSize) => {
  return groupByPrice(levels.map((level) => [roundToNearest(level[0], ticketSize), level[1]]));
};

export const formatNumber = (arg) => {
  return new Intl.NumberFormat("en-US").format(arg);
};

// Get current Date String
export const currentDateTimeFormat = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDateTime;
};

/**
 * Validates token expiration
 * @param {*} token
 * @returns Boolean
 */
export const isTokenValidate = (token) => {
  try {
    const { exp: expirationTime, role } = jwt_decode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return expirationTime > currentTime && ROLES[role];
  } catch (error) {
    // Handle error, token is not valid
    return false;
  }
};

/**
 * User details extraction from token
 * @param {*} token
 * @returns Object {}
 */
export const extractTokenDetails = (token) => {
  try {
    const userDetails = jwt_decode(token);
    return userDetails;
  } catch (error) {
    // Handle error, token is not valid
    return false;
  }
};

// Get get Date String from TimeStamp
export const getDateFromTimestamp = (timestamp) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'auto'
  const timestampDate = moment(timestamp);
  const formattedDateTime = timestampDate.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss.SSS');
  return formattedDateTime;
};

// common time format
export const getCommonDateTimeFormat = (date) => {
  return dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
};

// utc time
export const getUTCDateTimeFormat = (date) => {
  return dayjs(date).utc().format("MM/DD/YYYY hh:mm A");
};
