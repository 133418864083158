export const BASE_URL = "http://localhost:3000/quotes-api";

export const MOBILE_WIDTH = 800; // px

export const ORDERBOOK_LEVELS = 25; // rows count

export const MESSAGES = {
  API_ERROR: "Something Went wrong !",
  QUOTES_CREATE_SUCCESS: "Quotes Created Successfully !",
  QUOTES_POST_CREATE_SUCCESS: "Quotes Post Created Successfully !",
  QUOTES_POST_UPDATE_SUCCESS: "Quotes Post Updated Successfully !",
  SUCCESS_LOGIN: "Admin Login Successfully !",
  INSTRUMENT_CREATE_SUCCESS: "Instrumnet Create Successfully !",
  INSTRUMENT_ASSOCIATE: "Instrument Associate Successfully !",
  INSTRUMENT_DEASSOCIATE: "Instrument Dissociate Successfully !"
};

export const STATUS = {
  PENDING: "PENDING",
  SENT: "SENT",
  EXPIRED: "EXPIRED",
  NEGOTIATING: "NEGOTIATING",
  ORDER_SUBMITTED: "ORDER SUBMITTED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED"
};

export const ROLE = {
  DEALER: "DEALER",
  INVESTOR: "INVESTOR"
};

export const snackBarSuccessConf = {
  variant: "success",
  anchorOrigin: { vertical: "top", horizontal: "right" }
};

export const snackBarErrorConf = {
  variant: "error",
  anchorOrigin: { vertical: "top", horizontal: "right" }
};
