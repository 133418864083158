import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "Router/Routes";
import Loader from "Components/Shared/Loader";
import { Container } from "@mui/material";
import { useUserContext } from "Hooks/UserContext";

/**
 * Body of loggedIn User
 * @returns Body of the application
 */
const Body = () => {
  const { userDetails } = useUserContext();
  const CurrentUserRole = userDetails ? userDetails.role : "";
  return (
    <Container className="App" sx={{ padding: "0 10px 0 10px" }} maxWidth="fixed">
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Authenticated routes */}
          {privateRoutes[CurrentUserRole] &&
            privateRoutes[CurrentUserRole].map(({ path, ComponentIn }, key) => {
              return <Route key={key} path={path} element={<ComponentIn />} />;
            })}
          {/* Public routes for all users */}
          {publicRoutes.map(({ path, ComponentIn }, key) => {
            return <Route key={key} path={path} element={<ComponentIn />} />;
          })}
        </Routes>
      </Suspense>
    </Container>
  );
};

export default Body;
