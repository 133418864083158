import { quotesPrefix, identityPrefix } from "./config";

export class ApiService {
  basePath = "";
  middleware = "";
  constructor(basePath, middleware = {}) {
    this.basePath = basePath;
    this.middleware = middleware;
  }

  // Generic request for all the endpoints
  async httpRequest(method, path, body, schema = {}) {
    let response;
  
    try {
      let token = localStorage.getItem("Authorization");
      response = await fetch(`${this.basePath}${path}`, {
        method,
        headers: {
          ...(body ? { ["Content-Type"]: "application/json" } : {}),
          Authorization: `Bearer ${token}`
        },
        body: body ? JSON.stringify(body) : undefined
      });
    } catch (e) {
      if (e instanceof Error) {
        this.middleware.onError?.(e);
        return;
      }
    }
    // handle if error from API
    if (response && response.ok) {
      try {
        return await response.json();
      } catch (e) {
        if (e instanceof Error) {
          this.middleware.onError?.(e);
        }

        throw e;
      }
    } else {
      // handle all other errors
      await this.handleResponseError(response);
    }
  }

  // Handle API response errors
  async handleResponseError(response) {
    let json;
    try {
      json = await response.json();
    } catch (e) {
      json = e;
    }
    this.middleware.onError?.(json);
    throw json;
  }

  // Get all instruments
  getAllInstruments() {
    return this.httpRequest("GET", `${quotesPrefix}/investor/instrument`, null);
  }

  // Get single instrument
  getSingleInstrument(instrumentId) {
    return this.httpRequest("GET", `${quotesPrefix}/instrument/${instrumentId}`, null);
  }

  //Get all dealers by instrumentId
  getDealersByInstrumentId(instrumentId) {
    return this.httpRequest("GET", `${quotesPrefix}/dealer/instrument/${instrumentId}`, null);
  }

  //Get all dealers by instrumentId
  postQuotes(data) {
    return this.httpRequest("POST", `${quotesPrefix}/quotes`, data);
  }

  //fetch dealer quotes
  getAllQuotesDealer() {
    return this.httpRequest("GET", `${quotesPrefix}/dealer/quotes`);
  }

  //post quotes response
  postDealerResponse(data) {
    return this.httpRequest("POST", `${quotesPrefix}/dealer/response`, data);
  }

  // update dealer response
  updateDealerResponse(data) {
    return this.httpRequest("PUT", `${quotesPrefix}/dealer/response`, data);
  }

  //reject request by dealer(common merge later)
  rejectQuotesByDealer(data) {
    return this.httpRequest("POST", `${quotesPrefix}/quotes/complete`, data);
  }

  //get Dealer responses
  getDealerResponseByInstrumentandQuoteId(instrumentId, quoteId = "") {
    if (quoteId) {
      return this.httpRequest(
        "GET",
        `${quotesPrefix}/quotes/response/${instrumentId}?quoteId=` + quoteId
      );
    } else {
      return this.httpRequest("GET", `${quotesPrefix}/quotes/response/${instrumentId}`);
    }
  }

  getDealerResponseAllInstruments() {
    return this.httpRequest("GET", `${quotesPrefix}/quotes/response/all`);
  }

  //accept quote response from investor(common merge later)
  postAcceptQuoteByInvestor(data) {
    return this.httpRequest("POST", `${quotesPrefix}/quotes/complete`, data);
  }

  postRejectQuoteByInvestor(data) {
    return this.httpRequest("POST", `${quotesPrefix}/investor/response`, data);
  }

  //dealer history
  getDealerHistory() {
    return this.httpRequest("GET", `${quotesPrefix}/dealer/quotes/history`, null);
  }

  // two sided order
  twoSidedOrder(data) {
    return this.httpRequest("POST", `${quotesPrefix}/quotes/two-sided`, data);
  }

  //two side dealer response
  twoSideDealerResponse(data) {
    return this.httpRequest("POST", `${quotesPrefix}/dealer/response/two-sided`, data);
  }

  //two side negotiation
  twoSideNegotiation(data) {
    return this.httpRequest("POST", `${quotesPrefix}/investor/response/two-sided`, data);
  }

  //two side investor accepted
  twoSideQuotesAccepted(data) {
    return this.httpRequest("POST", `${quotesPrefix}/quotes/complete`, data);
  }

  // admin login
  adminLogin(data) {
    return this.httpRequest("POST", `${identityPrefix}/admin/login`, data);
  }

  getAllUserForAdmin() {
    return this.httpRequest("GET", `${identityPrefix}/admin/users`, null);
  }

  // Get all instruments
  getAllInstrumentsForAdmin() {
    return this.httpRequest("GET", `${quotesPrefix}/instrument/getAll`, null);
  }

  getUserDetails(userId) {
    return this.httpRequest("GET", `${identityPrefix}/user/${userId}`, null);
  }

  //enable disable user
  userEnableDisable(data) {
    return this.httpRequest("PUT", `${identityPrefix}/admin/user/${data.id}`, data.data);
  }

  //create instrument from admin
  createInstrument(data) {
    return this.httpRequest("POST", `${quotesPrefix}/admin/instrument`, data);
  }

  // get selected instrument by a user (adminSide)admin/instrument/${}
  getSelectedInstrument(userId) {
    return this.httpRequest("GET", `${quotesPrefix}/admin/instrument/${userId}`, null);
  }

  // update Instrument active/deactive
  activeDeactiveInstrument(data) {
    return this.httpRequest("PATCH", `${quotesPrefix}/admin/instrument/${data.id}`, data.data);
  }

  // instrument association
  adminInstrumentAssociation(data) {
    return this.httpRequest(
      "POST",
      `${quotesPrefix}/${data.type}/associateInstrument`,
      data.payload
    );
  }

  //remove instrument association
  removeInstrumentAssociation(data) {
    return this.httpRequest(
      "DELETE",
      `${quotesPrefix}/${data.type}/associateInstrument`,
      data.payload
    );
  }

  // get user fund Data
  getUserFundData() {
    return this.httpRequest("GET", `${quotesPrefix}/fund-balance`, null);
  }

  // update status of quote parent level
  expireQuotes(data) {
    return this.httpRequest("PUT", `${quotesPrefix}/quotes/expireQuotes`, data);
  }
}
