import React, { createContext, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthAlertDialog from "Components/Shared/AuthAlertDialog";
import { isTokenValidate, extractTokenDetails } from "Helpers/ats.helper";
import { publicPaths, Paths } from "Router/Paths";

const UserContext = createContext();

// User context
const useUserContext = () => {
  return useContext(UserContext);
};

// Context provider wrapper
const UserContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [userDetails, setuserDetails] = useState(null);
  const [isAuthErr, setAuthErr] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  let authorization;

  /**
   * handle role based redirection
   */
  const roleBasedRedirect = (auth) => {
    if (isTokenValidate(auth)) {
      const { role } = extractTokenDetails(auth);
      switch (role) {
        case "ADMIN":
          if (
            location.pathname === "/admin" ||
            location.pathname === "/admin/" ||
            location.pathname === "/"
          )
            navigate(Paths.users, { replace: true });
          break;
        case "INVESTOR":
          if (location.pathname === "/admin") navigate("/", { replace: true });
          break;
        case "DEALER":
          if (location.pathname === "/admin") navigate("/", { replace: true });
          break;
      }
    } else {
      // handled logged out user
      if (
        location.pathname != "/admin" &&
        location.pathname.startsWith("/admin") &&
        !isTokenValidate(authorization)
      ) {
        localStorage.removeItem("Authorization");
        authorization = null;
        navigate(Paths.admin);
      }
    }
  };

  /**
   * handle role based routing
   */
  useEffect(() => {
    const auth = localStorage.getItem("Authorization");
    roleBasedRedirect(auth);
  }, [location]);

  useEffect(() => {
    // Get existing token
    const auth = localStorage.getItem("Authorization");
    const activeInstrumentID = localStorage.getItem('activeInstrumentId')
    // Get new token from URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (auth && auth !== "null" && isTokenValidate(auth) && !urlParams.get("token")) {
      authorization = auth;
      setLoggedIn(true);
      if (activeInstrumentID)
        setActiveInstrument(activeInstrumentID)
      localStorage.removeItem('activeInstrumentId')
      setuserDetails(extractTokenDetails(auth));
      roleBasedRedirect(auth);
    } else {
      authorization = urlParams.get("token");
      let activeInstrument = urlParams.get("instrument")
      // authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
      /**
       * Check if token available and valid
       */
      const isPublicPath = publicPaths.includes(location.pathname.split("/")[1]);

      if (!isPublicPath) {
        if (!authorization) {
          setAuthErr(true);
          localStorage.removeItem("Authorization");
        } else {
          if (isTokenValidate(authorization)) {
            setAuthToken(authorization);
            setActiveInstrument(activeInstrument)
            localStorage.setItem("Authorization", authorization);
            localStorage.setItem("activeInstrumentId", activeInstrument);
            setuserDetails(extractTokenDetails(authorization));
            setLoggedIn(true);
            window.location.href = "/";
            // roleBasedRedirect(authorization);
          } else {
            setAuthErr(true);
            localStorage.removeItem("Authorization");
            authorization = null;
          }
        }
      }
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ userDetails, authToken, loggedIn, setLoggedIn, setuserDetails, setAuthErr, activeInstrument, setActiveInstrument }}>
      {isAuthErr ? <AuthAlertDialog /> : null}
      {children}
    </UserContext.Provider>
  );
};

export { useUserContext, UserContextProvider };
