import React from "react";
import Header from "Components/Shared/Header";
import AdminHeader from "./AdminHeader";
import Body from "Components/Shared/Body";
import { useUserContext } from "Hooks/UserContext";

const AppLayout = () => {
  const { loggedIn, userDetails } = useUserContext();
  return (
    <>
      {loggedIn ? userDetails && userDetails?.role == "ADMIN" ? <AdminHeader /> : <Header /> : ""}
      <Body />
    </>
  );
};
export default React.memo(AppLayout);
