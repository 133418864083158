export const Paths = {
  base: "/",
  nf: "*",
  dealerPanel: "dealer",
  investorHistory: "history",
  admin: "admin",
  users: "/admin/users",
  instruments: "/admin/instruments"
};

export const publicPaths = ["*", "admin"];
