import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ThemeSwitch from "./ThemeSwitch";
// import oasisLogo from "../../logo.svg";
import RfqLogoWhite from "../../RfqLogoWhite.svg";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import FundData from "./FundData";
import variables from "Styles/variables.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "Hooks/UserContext";
import LogoutIcon from "@mui/icons-material/Logout";

const pages = [
  {
    name: "Marketplace",
    // eslint-disable-next-line no-undef
    path: process.env.REACT_APP_MARKETPLACE_REDIRECT
  },
  {
    name: "ATS",
    path: "/ats"
  },
  {
    name: "RFQ",
    path: "/"
  },
  {
    name: "History",
    path: "/history",
    roleRestrictions: ["INVESTOR"]
  }
];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  //get User Details
  const { userDetails, setLoggedIn } = useUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    if (localStorage.getItem("Authorization")) {
      localStorage.removeItem("Authorization");
      setLoggedIn(false);
      setAnchorElUser(null);
      window.location.href = "/";
    }
  };

  const handleNavigation = (path) => {
    // Check if the path is an external link
    if (path.startsWith("http://") || path.startsWith("https://")) {
      // If it's an external link, open it in a new tab/window
      window.open(path, "_blank");
    } else {
      // Otherwise, handle it as an internal navigation
      navigate(path);
      setAnchorElUser(null);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        // boxShadow: "none",
        backgroundImage: "none",
        borderBottom: "1px solid rgb(228 228 228 / 20%)",
        boxShadow: "none"
      }}>
      <Container maxWidth="fixed">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              width: "130px"
            }}>
            <img style={{ width: "100%" }} src={RfqLogoWhite} alt="Oasis pro markets" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Settings"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}>
              {pages.map(
                (page) =>
                  (!page.roleRestrictions || page.roleRestrictions.includes(userDetails?.role)) && (
                    <MenuItem
                      key={page.name}
                      selected={page.path === pathname}
                      onClick={() => handleNavigation(page.path)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              width: "130px"
            }}>
            <img src={RfqLogoWhite} alt="Oasis pro markets" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(
              (page) =>
                (!page.roleRestrictions || page.roleRestrictions.includes(userDetails?.role)) && (
                  <Button
                    key={page.name}
                    onClick={() => handleNavigation(page.path)}
                    sx={{
                      my: 2,
                      color: page.path === pathname ? variables.secondary : variables.colorWhite,
                      display: "block"
                    }}>
                    {page == "MarketPlace" ? (
                      <Link
                        /* eslint-disable no-undef */
                        to={process.env.REACT_APP_MARKETPLACE_REDIRECT}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: variables.colorWhite }}>
                        {page}
                      </Link>
                    ) : (
                      page.name
                    )}
                  </Button>
                )
            )}
          </Box>

          {/* Box for fund and search */}
          <FundData />

          <Box sx={{ flexGrow: 0, display: "flex" }}>
            {/* <Button variant="contained" color="secondary">
              Connect wallet
            </Button> */}
            {/* Notification */}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              className="icon-button">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* User profile */}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} className="icon-button" sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <AccountCircle />
              </IconButton>
            </Tooltip>
            {/* Dark mode switch */}
            <ThemeSwitch />

            {/* User profile menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {/* <MenuItem onClick={handleCloseUserMenu}>Profile</MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>My account</MenuItem> */}
              {/* <MenuItem selected={pathname === "/"} onClick={() => navigate("/")}>
                Investor Panel
              </MenuItem>
              <MenuItem selected={pathname === "/dealer"} onClick={() => navigate("/dealer")}>
                Dealer Panel
              </MenuItem> */}
              <MenuItem>
                <Typography sx={{ pl: 1, pr: 1, display: "flex" }}>
                  <AccountCircleRoundedIcon fontSize="medium" sx={{ mr: 0.6 }} />
                  {userDetails && userDetails?.email}
                </Typography>
              </MenuItem>
              <MenuItem onClick={logout}>
                <Typography sx={{ pl: 1, pr: 1, display: "flex" }}>
                  <LogoutIcon fontSize="medium" sx={{ mr: 0.6 }} />
                  logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
