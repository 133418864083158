/* eslint-disable no-unused-vars */
import React, { createContext, useState, useContext, useEffect } from "react";

const InstrumentContext = createContext();
// User context
const useInstrumentContext = () => {
  return useContext(InstrumentContext);
};

const InstrumentContextProvider = ({ children }) => {
  const [activeInstrumentID, setActiveInstrumentID] = useState(null);
  const [activeQuoteID, setActiveQuoteID] = useState(null);
  return (
    <InstrumentContext.Provider value={{ activeInstrumentID, setActiveInstrumentID, activeQuoteID, setActiveQuoteID }}>
      {children}
    </InstrumentContext.Provider>
  );
};

export { useInstrumentContext, InstrumentContextProvider };
